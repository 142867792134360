import React from "react";
import Template from '../components/Template/Template';
import Faq from '../sections/Faq/Faq';
import attPdf from '../assets/pdf/ceidg_trio_color.pdf';

const Marki = () => {

    return (
        <Template path="/kontakt" click={() => alert("Click")}>
           
           <h1 className="ch1">Kontakt</h1>
           <section className="mWidth mw5" id="pContact">
                <div className="kontakt">
                    <h6 className="mb_heading"><span>Kontakt</span></h6>
                    <div className="mb_clear"></div>
                    <div>
                    <h3 className="kfnag"><strong>Jesteśmy z Wami od 2002 roku</strong></h3>
                    <div className="kfdiv">
                        <p><b>Dział handlowy i magazyn</b></p>
                        <p>ul. Pola Karolińskie 4 lok. 2<br />
                        <span>02-401</span> <span>Warszawa</span></p>
                        <strong>tel.:</strong> <strong><span><a className="kfa" href="tel:602594898">602-594-898</a></span></strong><br />
                        <strong>e-mail:</strong> <span><a href="mailto:info@sprzedajtoner.pl">info@sprzedajtoner.pl</a></span>
                    </div>
                    <div className="kfdiv">
                        <p><strong>Obsługa w siedzibie:</strong><br />
                        <br />Poniedziałek - piątek: 08:30 - 17:30<br />
                        <strong>Kontakt telefoniczny: 24h/7/365</strong></p>
                        <p>NIP: 5252206809<br />
                        <a className="kfa" href={attPdf} target="_blank">Nasz wpis w CEIDG - plik PDF</a></p>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                    </div>
                </div>
            </section>
    

        </Template>
    )
}

export default Marki
